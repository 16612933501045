
import Vue, { PropType } from 'vue';
import { parse, format } from 'date-fns';
import { ProposalStatusMonthObj } from '@/shared/types';
import { EChartsOption } from 'echarts/types/dist/shared';

import { UIUtilsServiceContract, ChartModelContract, ColorType, ColorModelContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import MonthPicker from '@/shared/legacy/monthPicker/MonthPicker.vue';
import EChart from '@/shared/legacy/eChart/EChart.vue';
import { NoData as NoChartData } from '@/shared/ui';

export default Vue.extend({
  components: { MonthPicker, EChart, NoChartData },

  name: 'ManagementReportingChart',

  useInjectable: [Services.UI, Models.Chart, Models.Colors],

  props: {
    chartName: {
      type: String,
      default: 'Management Tracking',
    },
    chartData: {
      type: Array as PropType<ProposalStatusMonthObj[]>,
      default: () => [],
    },
    activityTracking: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    startMonthRange: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    monthsRange: {
      type: Array as PropType<Date[]>,
      default: () => [],
    },
    dynamicColors: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    unitGrouping: string;
    sourceGrouping: string;
    maximumRange: number;
  } => ({
    sourceGrouping: 'user',
    unitGrouping: 'revenue',
    maximumRange: 12,
  }),

  computed: {
    chartItemColors() {
      if (!this.dynamicColors) {
        return ['#4da4da', '#eab93d', '#4ac367'];
      }
      const compulseColor = this.$c360?.colors?.primary || '';
      const startColor = compulseColor && compulseColor.includes('#') ? compulseColor : '#7abcfb';
      const numberOfColors = this.chartLegendItems?.length || 6;
      return (this[Models.Colors] as ColorModelContract).getGeneratedColors({
        numberOfColors,
        baseColor: startColor,
        type: ColorType.hex,
      });
    },
    byAgency(): boolean {
      return this.sourceGrouping === 'agency';
    },
    byProposals(): boolean {
      return this.unitGrouping === 'proposals';
    },
    chartLegendItems(): string[] {
      return (this.chartEntity as ChartModelContract).managementChartLegend({
        chartData: this.chartData,
        activityTracking: this.activityTracking,
        byAgency: this.byAgency,
      });
    },
    chartOptions(): EChartsOption {
      return {
        color: this.chartItemColors,
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          width: '95%',
          containLabel: true,
        },
        legend: {
          top: 0,
          left: 20,
          itemWidth: 12,
          itemHeight: 12,
          icon: 'circle',
          data: this.chartLegendItems,
        },
        xAxis: [
          {
            type: 'category',
            nameLocation: 'middle',
            boundaryGap: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#333',
                type: 'solid',
              },
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
              lineStyle: {
                color: '#333',
                type: 'solid',
              },
            },
            data: this.mapIncomingDataToChartCategories(),
          },
        ],
        yAxis: {
          axisLabel: {
            margin: 8,
            show: true,
            formatter: value => {
              const formattedValue = this.bigNumberFormatter(value);
              return this.byProposals ? value : `$ ${formattedValue}`;
            },
          },
          nameGap: 30,
        },
        series: this.mapIncomingDataToChartData(),
      };
    },
  },

  methods: {
    onWindowResize(): void {
      setTimeout(() => {
        if (this.$refs?.lineChart?.chartInstance) {
          this.$refs.lineChart.chartInstance.resize();
        }
      }, 250);
    },
    priceStringAsNumber(price: string): number {
      if (typeof price === 'number') return price;
      return parseFloat(price.replace(/\$|,/g, ''));
    },
    bigNumberFormatter(number: number): string | number {
      return (this.uiUtilsService as UIUtilsServiceContract).bigNumberFormatter(number);
    },
    onMonthsUpdated(dates) {
      if (this.monthsRange[0] !== dates[0] || this.monthsRange[1] !== dates[1]) {
        this.$emit('update-months', dates);
      }
    },
    mapIncomingDataToChartData(): EChartsOption[] {
      return (this.chartEntity as ChartModelContract).mapRawMonthlyChartDataY({
        chartData: this.chartData,
        byUser: !this.byAgency,
        byCount: !this.byProposals,
        priceToNumberFormatter: this.priceStringAsNumber,
      });
    },
    mapIncomingDataToChartCategories(): string[] {
      if (this.chartData[0].Date !== undefined) {
        return (this.chartEntity as ChartModelContract).mapRawDaylyChartDataX({
          chartData: this.chartData,
          dateParser: parse,
          dateFormatter: format,
        });
      }

      return (this.chartEntity as ChartModelContract).mapRawMonthlyChartDataX({
        chartData: this.chartData,
        dateParser: parse,
        dateFormatter: format,
      });
    },
  },
});
