
import Vue from 'vue';
import { sortDates, formatDateForPicker, formatDateMonthNameWithYear, calcRange } from './utils';

export default Vue.extend({
  props: {
    monthsUpdated: Function,
    customRangeFormatter: Function,
    maximumRange: Number,
    color: {
      type: String,
      default: 'primary',
    },
    buttonColor: {
      type: String,
      default: 'light-blue',
    },
    labelColor: {
      type: String,
      default: '#eceff1',
    },
    startMonthRange: {
      type: Array,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    menu: boolean;
    rangeOutputStr: string;
    dates: [];
  } => ({
    menu: false,
    rangeOutputStr: ' - ',
    dates: [],
  }),
  beforeMount() {
    this.setDatesToCurrentDay();
    // if start month range is set
    if (undefined !== this.startMonthRange) {
      // if set only one month, use it with current month
      if (this.startMonthRange.length === 1) {
        if (new Date() > this.startMonthRange[0]) {
          this.dates[0] = formatDateForPicker(this.startMonthRange[0]);
        } else {
          this.dates[1] = formatDateForPicker(this.startMonthRange[0]);
        }
      } else {
        // use provided month range
        this.dates = [formatDateForPicker(this.startMonthRange[0]), formatDateForPicker(this.startMonthRange[1])];
      }
    }

    this.displayRange();
  },
  mounted() {
    this.monthsUpdated([...this.dates]);
  },
  updated() {
    this.displayRange();
    if (Math.abs(calcRange(this.dates[0], this.dates[1])) > this.maximumRange) {
      const start = this.dates[1];
      this.dates = [start];
    }
  },
  methods: {
    setDatesToCurrentDay() {
      const now = new Date();
      const nowFormatted = formatDateForPicker(now);
      this.baseDate = nowFormatted;
      this.dates = [nowFormatted, nowFormatted];
    },
    displayRange() {
      const dates = sortDates(this.dates);
      this.rangeOutputStr = `
      ${formatDateMonthNameWithYear(dates[0])} - ${formatDateMonthNameWithYear(dates[1])}
      `;
    },
    setRange() {
      this.monthsUpdated(sortDates(this.dates));
      this.menu = false;
    },
  },
});
