
import Vue from 'vue';
import { add, sub } from 'date-fns';
import { NewProposalRoute, Proposal, ProposalRevenueData, AdProductUser, ProposalStatusMonthObj } from '@/shared/types';

import {
  Status,
  UXUtilsServiceContract,
  LocalStorageServiceContract,
  RouterModelContract,
  StatusModelContract,
  TrackerContract,
  ProposalServiceContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { Routes } from '@/router/routes';

import ClientInfoDialog from '@/components/Clients/ClientInfoDialog.vue';
import ActiveProductsWidget from '@/widgets/home/active-products.vue';
import PerformanceWidget from '@/widgets/home/performance.vue';
import ManagementReportingWidget from '@/widgets/home/management-reporting.vue';
import EndingCampaignsWidget from '@/widgets/home/ending-campaigns.vue';
import QueuesWidget from '@/widgets/home/queues.vue';
import AppBar from '@/widgets/home/summary-app-bar.vue';
import ProposalProductCount from '@/widgets/home/proposal-product-count.vue';
import { mapGetters } from 'vuex';
import { Nullable } from '@/shared/types';

export default Vue.extend({
  name: 'Home',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Models.Status, Services.Tracker, Models.Router, Services.Proposal],

  components: {
    ClientInfoDialog,
    ManagementReportingWidget,
    EndingCampaignsWidget,
    ActiveProductsWidget,
    PerformanceWidget,
    QueuesWidget,
    AppBar,
    ProposalProductCount,
  },

  data: (): {
    loadingProposalStatusChartData: boolean;
    proposalStatusChartMonths: string[];
    loadingUserAgencyActivityChartData: boolean;
    userAgencyActivityChartMonths: string[];
    proposalProductsChartMonths: string[];
    loadingActiveProducts: boolean;
    loadingProposalProductsActivityChartData: boolean;
    selectedClientId: string | null;
    forceUpdateProposalsList: boolean;
  } => ({
    // management charts
    loadingUserAgencyActivityChartData: false,
    userAgencyActivityChartMonths: [],
    proposalProductsChartMonths: [],
    loadingProposalStatusChartData: false,
    proposalStatusChartMonths: [],
    loadingActiveProducts: false,
    selectedClientId: null,
    forceUpdateProposalsList: false,
    loadingProposalProductsActivityChartData: false,
  }),

  watch: {
    proposalStatusChartMonths(dates: Date[]): void {
      if (dates.length === 2) {
        this.fetchManagementChartData('proposalStatus');
      }
    },
    userAgencyActivityChartMonths(dates: Date[]): void {
      if (dates.length === 2) {
        this.fetchManagementChartData('activityTracking');
      }
    },
    proposalProductsChartMonths(dates: Date[]): void {
      if (dates.length === 2) {
        this.fetchManagementChartData('proposalProducts');
      }
    },
  },

  computed: {
    ...mapGetters('auth', {
      isAgencyAdmin: 'isAgencyAdmin',
    }),
    // management charts
    managementChartsStartMonthRange(): Date[] {
      const now = new Date();
      return [sub(now, { months: 3 }), add(now, { months: 2 })];
    },
    proposalStatusChartData(): ProposalStatusMonthObj[] {
      return this.$store.state.management.proposalStatusChartData;
    },
    userAgencyActivityChartData(): ProposalStatusMonthObj[] {
      return this.$store.state.management.userAgencyActivityChartData;
    },
    proposalProductsChartData() {
      return this.$store.state.management.proposalProductsChartData;
    },
    refetchProposals(): boolean {
      return this.$store.state.status.refetchProposalsTrigger;
    },
    proposalsWidgetHeader(): string {
      return this.isAgencyAdmin && this.proposalsQueue?.length > 0 ? 'Proposals Queue' : 'Recent Proposals';
    },
    loadingProposals(): boolean {
      return this.$store.state['proposal'].loadingAllProposals;
    },
    loadingEndingCampaigns(): boolean {
      return this.$store.state['proposal'].loadingEndingCampaigns;
    },
    loadingProposalsQueue(): boolean {
      return this.$store.state['proposal'].loadingProposalsQueue;
    },
    activeProductsChartData(): ProposalRevenueData[] {
      return this.$store.state['proposal'].activeProducts;
    },
    agencyInfo() {
      return this.$store.state.agency.currentAgencyInfo;
    },
    user(): AdProductUser {
      return this.$store.state.auth.user;
    },
    proposalsSummaryLoading(): boolean {
      return (
        this.$store.state.proposal.proposalsSummaryLoading || this.$store.state.proposal.proposalsSummaryByClientLoading
      );
    },
  },

  methods: {
    // summary chart
    fetchProposalsSummary({ from, to }): void {
      this.$store.dispatch('proposal/getProposalsSummary', { from, to });
    },
    // management charts
    async fetchManagementChartData(chartType: string): Promise<ProposalStatusMonthObj[]> {
      const chartMap = {
        proposalStatus: {
          action: 'getProposalStatusChartData',
          loading: 'loadingProposalStatusChartData',
          months: 'proposalStatusChartMonths',
        },
        activityTracking: {
          action: 'getUserAgencyActivityChartData',
          loading: 'loadingUserAgencyActivityChartData',
          months: 'userAgencyActivityChartMonths',
        },
        proposalProducts: {
          action: 'getProposalProductsChartData',
          loading: 'loadingProposalProductsActivityChartData',
          months: 'proposalProductsChartMonths',
        },
      };

      const chartParams = chartMap[chartType];

      const [start, end] = this[chartParams.months];

      this[chartParams.loading] = true;

      const chartData = await this.$store.dispatch(`management/${chartParams.action}`, { start, end });

      this[chartParams.loading] = false;

      return chartData;
    },
    async openClientInfoDialog(id: string): Promise<void> {
      await this.$store.dispatch('client/updateActiveClientId', id);
      this.selectedClientId = id;
    },
    closeClientInfoDialog() {
      this.selectedClientId = null;
    },
    navigateToProposals(variant: 'ending' | 'queue' | 'recent') {
      const query = (this.routerEntity as RouterModelContract).getProposalQuery(variant);

      this.$router.push({
        name: Routes.Proposals,
        query,
      });
    },
    fetchActiveProductsChartData(): void {
      this.loadingActiveProducts = true;

      this.$store
        .dispatch('proposal/getProposalsRevenueData')
        .catch(err => this.$log('error', 'home/fetchActiveProductsChartData', err))
        .finally(() => (this.loadingActiveProducts = false));
    },
    getVisitedRoute(proposalId: string): NewProposalRoute {
      const storageService: LocalStorageServiceContract = this.$container.get(Services.LocalStorage);
      const uxService: UXUtilsServiceContract = this.$container.get(Services.UX);

      const { email } = this.user;

      return uxService.getProposalStepForUser({
        email,
        proposalId,
        storageService,
      });
    },
    async saveStatus({
      propertyId,
      newStatus,
      oldStatus,
    }: {
      propertyId: string;
      newStatus: string;
      oldStatus: string;
    }): Promise<boolean> {
      let skipStatusUpdate = false;
      let updatedProposal: Nullable<Proposal> = null;
      this.loadingUpdateStatusProposal = propertyId;

      const isAdminQueue = this.isAgencyAdmin && this.currentTab === 'proposalsQueue';

      if (this.agencyInfo.adminReviewEnabled && newStatus === Status.ClientApproved) {
        updatedProposal = await this.$store.dispatch('proposal/updateProposalStatus', {
          proposalPropertyId: propertyId,
          newStatus,
          isAdminQueue,
        });

        const submittedStatus = Status.SubmittedForReview;

        const prettyStatus = (status: Status): string =>
          (this.statusEntity as StatusModelContract).prettyStatus(status, this.agencyInfo.adminReviewEnabled).short;

        const { confirmed } = await this.$confirm.show({
          title: 'Submit for Admin Approval?',
          body: `This will change the proposal status to ${prettyStatus(submittedStatus)}`,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (confirmed) {
          newStatus = submittedStatus;
        } else {
          skipStatusUpdate = true;
        }
      }

      if (!skipStatusUpdate) {
        updatedProposal = await this.$store.dispatch('proposal/updateProposalStatus', {
          proposalPropertyId: propertyId,
          newStatus,
          isAdminQueue,
        });
      }

      if (!updatedProposal) {
        this.$log('error', 'home/saveStatus', `Unable to update proposal status for ${propertyId} to ${newStatus}`);
        this.showSnackbar('Update status failed');
        return false;
      }

      const { id } = this.$store.state.auth.user;
      const clientCategory = updatedProposal?.client?.category?.name?.split('^')?.join(', ');

      (this.tracker as TrackerContract).trackProposalStatusChange({
        proposal: updatedProposal,
        userId: id,
        updatedAt: new Date().toISOString(),
        from: oldStatus,
        to: newStatus,
        clientCategory,
      });

      this.loadingUpdateStatusProposal = '';
      // WORKAROUND [TO DO] use cache update instead
      this.$refs.proposalQueue.getAllProposals();
      return !!updatedProposal;
    },
    async navigateToNewProposal(value): Promise<void> {
      const redirectToStep = (link: Routes): void => {
        this.$router.push({ name: link, params: { id: value.client.id, proposalId: value.id || '' } });
      };

      const visitedRoute = this.getVisitedRoute(value.id);

      if ([Status.Sold, Status.ClosedLost].includes(value.status)) {
        redirectToStep(Routes.ProposalSummary);
      } else if (
        this.$store.state.agency.currentAgencyInfo.adminReviewEnabled &&
        value.status === Status.SubmittedForReview &&
        this.isAgencyAdmin
      ) {
        const { confirmed } = await this.$confirm.show({
          title: 'Begin Reviewing Proposal?',
          body: "This will change the proposal status to 'Under Review'",
          confirmText: 'Yes',
          cancelText: 'Cancel',
        });

        if (!confirmed) {
          redirectToStep(Routes.ProposalSummary);
          return;
        }

        await this.saveStatus({
          propertyId: value.id,
          newStatus: Status.UnderReview,
        });

        redirectToStep(Routes.ProposalSolutions);
      } else if (visitedRoute) {
        redirectToStep(visitedRoute.name);
        return;
      } else {
        redirectToStep(Routes.ProposalMarket);
      }
    },
  },

  created(): void {
    this.fetchActiveProductsChartData();
  },
});
