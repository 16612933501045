
import Vue from 'vue';

import BaseAppBar from '@/entities/app/base-app-bar.vue';
import CreateCampaignBtn from '@/entities/campaign/ui/CreateCampaignBtn.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SummaryAppBarWidget',

  inject: ['toggleDrawer'],

  components: {
    BaseAppBar,
    CreateCampaignBtn,
  },

  computed: {
    ...mapGetters({ isAdmin: 'auth/isAgencyAdmin' }),
    instantIoEnabled() {
      return this.$store.state.agency?.currentAgencyInfo?.instantIOEnabled;
    },
  },
  methods: {
    setInstantIOModalQuery(): void {
      this.$router.replace({
        path: this.$route.path,
        query: {
          modal: 'insertionOrders',
        },
      });
    },
  },
});
