
import Vue from 'vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

export default Vue.extend({
  name: 'EChart',
  props: ['chartOptions', 'forceClear'],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
  } => ({
    chartInstance: null,
  }),
  mounted() {
    setTimeout(() => {
      if (!this.$refs.canvas) {
        // eslint-disable-next-line no-console
        console.error('echart.vue', 'no canvas');
        this.$emit('rendered', { empty: true });
        return;
      }
      this.chartInstance = ECharts.init(this.$refs.canvas);

      if (this.chartInstance) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const c = this.chartInstance as any;
        c.on('finished', () => {
          this.$emit('rendered', { empty: false });
        });
        c.on('rendered', () => {
          this.$emit('rendered', { empty: false });
        });
        c.setOption(this.chartOptions);
      } else {
        // eslint-disable-next-line no-console
        console.error('initChart', 'failed to create instance');
      }
    }, 10);
  },
  watch: {
    chartOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any, oldVal: any): void {
        if (this.chartInstance) {
          if (this.forceClear && newVal?.series?.length !== oldVal?.series?.length) {
            this.chartInstance.clear();
          }
          this.chartInstance.setOption(this.chartOptions);
        }
      },
      deep: true,
    },
  },
});
