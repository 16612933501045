
import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'CreateCampaignBtn',

  components: {
    C360Icon,
  },

  data() {
    return {
      shareMenu: false,
    };
  },
});
