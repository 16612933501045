
import Vue from 'vue';
import PieChart from '@/components/PieChart.vue';

export default Vue.extend({
  name: 'ActiveProductsWidget',

  components: {
    PieChart,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
    },
  },
});
