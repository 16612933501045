import { render, staticRenderFns } from "./ending-campaigns.vue?vue&type=template&id=6df6c033&scoped=true"
import script from "./ending-campaigns.vue?vue&type=script&lang=ts"
export * from "./ending-campaigns.vue?vue&type=script&lang=ts"
import style0 from "./ending-campaigns.vue?vue&type=style&index=0&id=6df6c033&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df6c033",
  null
  
)

export default component.exports