
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';

import { Models } from '@/injectables/tokens';
import { Status, StatusModelContract, NewProposalModelContract, DateModelContract } from '@/injectables';

import NavigationButtons from '@/components/NavigationButtons.vue';
import ProposalActionsMenu from '@/components/Proposals/ProposalActionsMenu.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProposalStatusSelector from '@/components/ProposalStatusSelector.vue';
import { Proposal } from '@/shared/types';
import { debounce } from 'lodash';
import ReassignCampaignModal from '@/features/reassign-campaign-modal/ReassignCampaignModal.vue';

interface Tab {
  name: string;
  pathName: string;
  disabled: boolean;
}

interface Data {
  reassignCampaignModalProps: {
    dialog: boolean;
    campaignId: string;
    campaignName?: string;
    createdBy;
  };
  tabs: Tab[];
  headers: DataTableHeader[];
  options: {
    sortBy: string;
    sortDesc: Boolean | undefined;
  };
  currentTab: 'recentProposals' | 'proposalsQueue';
  loadingUpdateStatusProposal: string;
}

export default Vue.extend({
  name: 'QueuesWidget',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Models.Status, Models.NewProposal, Models.Date],

  components: {
    ProposalActionsMenu,
    WrapperWithTooltip,
    NavigationButtons,
    ProposalStatusSelector,
    ReassignCampaignModal,
  },

  props: {
    forAgencyAdmin: {
      type: Boolean,
      required: true,
    },
    reviewEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data(): Data {
    return {
      reassignCampaignModalProps: {
        dialog: false,
        campaignId: '',
        campaignName: '',
        createdBy: null,
      },
      tabs: [
        {
          name: 'Proposals Queue',
          pathName: 'proposalsQueue',
          disabled: false,
        },
        {
          name: 'Recent Proposals',
          pathName: 'recentProposals',
          disabled: false,
        },
      ],
      headers: [
        { text: '', width: '50px', align: 'start', value: 'client.logo', sortable: false, filterable: false },
        { text: 'Client', align: 'start', width: '150px', value: 'client.name' },
        { text: 'Proposal Name', width: '170px', value: 'name' },
        { text: 'Budget', width: '90px', value: 'budget' },
        { text: 'Start Date', width: '110px', value: 'campaignStartDate' },
        ...(this.forAgencyAdmin ? [{ text: 'User', value: 'createdBy.email', width: '115px', filterable: false }] : []),
        { text: 'Status', width: '120px', value: 'status' },
        { text: '', align: 'end', value: 'actions', width: '6%', sortable: false },
      ],
      options: {
        sortBy: 'updatedAt',
        sortDesc: true,
      },
      currentTab: 'recentProposals',
      loadingUpdateStatusProposal: '',
    };
  },
  async mounted() {
    if (this.forAgencyAdmin) {
      await this.getProposalQueue();
    }
    if (this.proposalsQueue.length) {
      this.updateTab('proposalsQueue');
    }
    await this.getRecentProposal();
  },

  computed: {
    agencyInfo() {
      return this.$store.state.agency.currentAgencyInfo;
    },
    proposalsList() {
      if (this.currentTab === 'recentProposals') {
        return this.recentProposals;
      }
      return this.proposalsQueue;
    },
    recentProposals() {
      return this.$store.state.proposal.allProposals?.list || [];
    },
    proposalsQueue() {
      return this.$store.state.proposal.adminQueue?.list;
    },
    loadingProposalsQueue(): boolean {
      return this.$store.state['proposal'].loadingProposalsQueue;
    },
    loadingProposals(): boolean {
      return this.$store.state['proposal'].loadingAllProposals;
    },
    loading(): boolean {
      return this.loadingProposalsQueue || this.loadingProposals;
    },
  },

  methods: {
    openReassignDialog({
      id,
      campaignName,
      createdBy,
    }: {
      id: string | number;
      campaignName: string;
      createdBy;
    }): void {
      this.reassignCampaignModalProps.dialog = true;
      this.reassignCampaignModalProps.campaignId = id;
      this.reassignCampaignModalProps.campaignName = campaignName;
      this.reassignCampaignModalProps.createdBy = createdBy;
    },
    dateToString(date: string): string {
      return (this.dateEntity as DateModelContract).dateToFormatDateString(date);
    },
    prettyStatus(status: string) {
      const statusEntity: StatusModelContract = this.statusEntity;
      return statusEntity.prettyStatus(status, this.agencyInfo.adminReviewEnabled).short;
    },
    proposalIsChangeDisabled(proposalStatus: Status): boolean {
      return (this.newProposalEntity as NewProposalModelContract).newProposalIsChangeDisabled({
        proposalStatus,
        isAgencyAdmin: this.forAgencyAdmin,
      });
    },
    availableStatusTransitions(proposal: Proposal): string[] {
      if (proposal.status === Status.Approved) {
        return [Status.InProgress];
      }

      return proposal.nextStatuses || [];
    },
    async updateSort(sortBy) {
      if (this.options.sortBy === sortBy) return;
      this.options.sortBy = sortBy;

      await this.getAllProposals();
    },
    async updateSortType(desc) {
      if (this.options.sortDesc === desc) return;
      this.options.sortDesc = desc;

      await this.getAllProposals();
    },
    getProposalQueue({ limit = 5, sortBy = this.options.sortBy, sortDesc = this.options.sortDesc } = this.options) {
      return this.$store.dispatch('proposal/getAdminQueueProposals', { sortBy, sortDesc, limit });
    },
    getProposalQueueDebounced: debounce(function (
      { limit = 5, sortBy = this.options.sortBy, sortDesc = this.options.sortDesc } = this.options,
    ) {
      return this.getProposalQueue({ limit, sortBy, sortDesc });
    },
    300),
    getRecentProposal: debounce(function (
      { limit = 5, sortBy = this.options.sortBy, desc = this.options.sortDesc } = this.options,
    ) {
      return this.$store.dispatch('proposal/getAllProposals', {
        limit,
        sortBy,
        desc,
      });
    },
    300),
    updateTab(tab: 'recentProposals' | 'proposalsQueue'): void {
      this.currentTab = tab;
    },
    getAllProposals: debounce(async function () {
      if (this.forAgencyAdmin) {
        await this.getProposalQueueDebounced();
      }
      await this.getRecentProposal();
    }, 300),
  },
});
