
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { debounce } from 'lodash';

interface Data {
  headers: DataTableHeader[];
  options: {
    sortBy: string;
    sortDesc: Boolean | undefined;
  };
}

export default Vue.extend({
  name: 'EndingCampaignsWidget',

  components: {
    WrapperWithTooltip,
  },

  mounted() {
    this.getEndingCampaigns();
  },

  data(): Data {
    return {
      headers: [
        { text: '', width: '50px', align: 'start', value: 'client.logo', sortable: false, filterable: false },
        { text: 'Client', width: '150px', value: 'client.name' },
        { text: 'Spend', width: '90px', value: 'budget' },
        { text: 'Ending in', width: '120px', value: 'campaignEndDate' },
      ],
      options: {
        sortBy: 'campaignEndDate',
        sortDesc: false,
      },
    };
  },
  computed: {
    endingCampaigns() {
      return this.$store.state.proposal.endingCampaigns?.list || [];
    },
    loading() {
      return this.$store.state.proposal.loadingEndingCampaigns;
    },
  },
  methods: {
    getEndingCampaigns: debounce(function ({ sortBy, sortDesc } = this.options) {
      this.$store.dispatch('proposal/getEndingCampaigns', { sortBy, sortDesc });
    }, 300),
    async updateSort(sortBy) {
      if (this.options.sortBy === sortBy) return;
      this.options.sortBy = sortBy;

      await this.getEndingCampaigns();
    },
    async updateSortType(desc) {
      if (this.options.sortDesc === desc) return;
      this.options.sortDesc = desc;

      await this.getEndingCampaigns();
    },
  },
});
