
import Vue, { PropType } from 'vue';
import { parse, format, sub, add } from 'date-fns';

import { UIUtilsServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { ProposalSummary, UnsafeAny } from '@/shared/types';

import EChart from '@/shared/legacy/eChart/EChart.vue';
import MonthPicker from '@/shared/legacy/monthPicker/MonthPicker.vue';
import { NoData as NoChartData } from '@/shared/ui';

interface Data {
  monthsRange: string[];
  chart: UnsafeAny;
}

export default Vue.extend({
  name: 'ProposalsSummaryChart',

  useInjectable: [Services.UI],

  components: { MonthPicker, EChart, NoChartData },

  props: {
    dataSet: {
      type: Array as PropType<ProposalSummary[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: (): Data => ({
    monthsRange: [],
    chart: null,
  }),

  computed: {
    chartOptions(): UnsafeAny {
      const proposalsSummary = this.dataSet;

      return {
        color: ['#639fe0', '#e1b95d'],
        tooltip: {
          show: true,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        legend: {
          top: 0,
          left: 20,
          itemWidth: 12,
          itemHeight: 12,
          icon: 'circle',
          data: ['Total', 'Signed'],
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: false,
            },
            data: this.mapIncomingDataToChartCategories(proposalsSummary),
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisLabel: {
              margin: 8,
              show: true,
              formatter: value => {
                const formattedValue = this.bigNumberFormatter(value);
                return `$ ${formattedValue}`;
              },
            },
          },
        ],
        series: this.mapIncomingDataToChartData(proposalsSummary),
      };
    },
    startMonthRange() {
      const now = new Date();
      return [sub(now, { months: 4 }), add(now, { months: 1 })];
    },
  },

  methods: {
    onWindowResize(): void {
      setTimeout(() => {
        if (this.$refs?.performanceChart?.chartInstance) {
          this.$refs.performanceChart.chartInstance.resize();
        }
      }, 250);
    },
    bigNumberFormatter(number: number): string | number {
      return (this.uiUtilsService as UIUtilsServiceContract).bigNumberFormatter(number);
    },
    onMonthsUpdated(dates) {
      if (this.monthsRange[0] !== dates[0] || this.monthsRange[1] !== dates[1]) {
        this.monthsRange = dates;
        this.fetchProposalsSummary(this.monthsRange[0], this.monthsRange[1]);
      }
    },
    fetchProposalsSummary(from: string, to: string): void {
      this.$emit('get-proposals-summary', { from, to });
    },
    mapIncomingDataToChartData(input: UnsafeAny[]): UnsafeAny[] {
      const defaultBarOptions = {
        type: 'bar',
        barWidth: 20,
        barMinHeight: 5,
        itemStyle: {
          borderRadius: [3, 3, 0, 0],
        },
      };

      const result = [
        {
          ...defaultBarOptions,
          name: 'Total',
          stack: 'total',
          data: [],
        },
        {
          ...defaultBarOptions,
          name: 'Signed',
          stack: 'signed',
          data: [],
        },
      ];

      input.forEach((i: UnsafeAny) => {
        const hasData = i.totalCount > 0;
        const hasSigned = i.signedCount > 0;

        result[0].data.push({
          value: i.totalCost,
          label: {
            show: true,
            color: hasData ? '#555' : '#bfbfbf',
            position: hasData ? 'top' : ['1', '-10'],
            formatter: hasData ? String(i.totalCount) : 'No Data',
          },
          tooltip: {
            show: !!hasData,
            formatter: `{b}, {a}:<br/> ${i.totalCount} for ${this.$options.filters.formatPrice(i.totalCost)}`,
          },
          itemStyle: { color: hasData ? void 0 : 'rgba(0,0,0,0)' },
        });

        if (hasSigned) {
          result[1].data.push({
            value: i.signedCost,
            label: {
              show: true,
              color: '#555',
              position: 'top',
              formatter: hasData ? String(i.signedCount) : '',
            },
            tooltip: {
              show: !!hasData,
              formatter: `{b}, {a}:<br/> ${i.signedCount} for ${this.$options.filters.formatPrice(i.signedCost)}`,
            },
            itemStyle: { color: hasData ? void 0 : 'rgba(0,0,0,0)' },
          });
        } else {
          result[1].data.push(null);
        }
      });

      return result;
    },
    mapIncomingDataToChartCategories(input: ProposalSummary[]): UnsafeAny[] {
      return input.map(i => {
        const parsed = parse(`${i.month}`, 'yyyy-MM', new Date());
        if (isNaN(parsed.getTime())) {
          // eslint-disable-next-line no-console
          console.error(`Error parsing inoming time: expected yyyy-MM format, got ${i.month}`);
          return 'Wrong Date';
        }

        return format(parsed, 'MMM, yyyy');
      });
    },
  },
});
