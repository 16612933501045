
import Vue from 'vue';

import ProposalsSummaryChart from '@/components/ProposalsSummaryChart.vue';

export default Vue.extend({
  name: 'PerformanceWidget',

  components: {
    ProposalsSummaryChart,
  },

  props: {
    dataSet: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
