import { format, parse, differenceInMonths } from 'date-fns';
import { enUS } from 'date-fns/locale';

// format date from yyyy-MM or Date to full month name
export function formatDateMonthNameWithYear(rawDate: Date | string): string {
  if (rawDate === undefined) {
    return '';
  }

  let date;

  if (typeof rawDate === 'string') {
    date = parse(rawDate, 'yyyy-MM', new Date());
  } else {
    date = rawDate;
  }

  return format(date, 'MMM yyyy', { locale: enUS });
}

export function formatDateFromPicker(rawDate: string): Date {
  return parse(rawDate, 'yyyy-MM', new Date());
}

// format date from datetime to yyyy-MM
export function formatDateForPicker(rawDate: Date): string {
  return format(rawDate, 'yyyy-MM', { locale: enUS });
}

// sort dates (not in-place) and return sorted array
export function sortDates(dates: string[]): string[] {
  return [...dates].sort((a, b) => formatDateFromPicker(a).getTime() - formatDateFromPicker(b).getTime());
}

// calculate range between dates
export function calcRange(date1: string, date2: string): number {
  return differenceInMonths(formatDateFromPicker(date1), formatDateFromPicker(date2));
}
